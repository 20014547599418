import React, { useEffect, useState } from "react";
import { Container, Col, Form, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import PageSizeSelector from "./PageSizeSelector";
import { useDispatch, useSelector } from "react-redux";
import { CurrentTab } from "../helper/generalHelper";
import { setCcvCurrentPage } from "../store/site/ccv/ccvSlice";
import { setRiekerCurrentPage } from "../store/site/rieker/riekerSlice";
import { setMmaxCurrentPage } from "../store/site/mmax/mmaxSlice";
import { setMustangCurrentPage } from "../store/site/mustang/mustangSlice";
import { setRdhCurrentPage } from "../store/site/rdh/rdhSlice";
import { resetOrderSelectedRows } from "../store/generaldata/generalDataSlice";

const PaginationBottom = ({
  pageSize,
  handlePageSize,
  pageNumber,
  handlePageNumber,
  FetchOrders,
}) => {
  let listing = useSelector((state) => state.generalData.listingView);
  let currentSite = CurrentTab();
  if(currentSite === 'CCVZalando') {
    currentSite = 'CCV';
  }
  const [tempPageNo, setTempPageNo] = useState(
    useSelector((state) => state[currentSite.toLowerCase()].currentPage)
  );
  const dispatch = useDispatch();
  const orderCount = useSelector(
    (state) => state[currentSite.toLowerCase()].totalOrders
  );
  const prepOrderCount = useSelector(
    (state) => state[currentSite.toLowerCase()].totalPrepOrders
  );
  const checkCount = listing === "order" ? orderCount : prepOrderCount;
  const currentPage = useSelector(
    (state) => state[currentSite.toLowerCase()].currentPage
  );
  const totalPages = Math.ceil(checkCount / pageSize);

  const pageStart = currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1;
  const pageEnd =
    parseInt(currentPage) === parseInt(totalPages)
      ? checkCount
      : currentPage * pageSize;

  useEffect(() => {
    setTempPageNo(currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, listing, currentSite]);

  useEffect(() => {
    handlePageSize(35);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing, currentSite]);

  const handlePrevClick = () => {
    dispatch(resetOrderSelectedRows());
    const updatedPageNo = parseInt(tempPageNo) - 1;
    setTempPageNo(updatedPageNo);
    if (tempPageNo <= 0 || !tempPageNo) {
      alert("Not a valid page number");
      setTempPageNo(1);
      return;
    }
    const siteActionName =
      currentSite.charAt(0).toUpperCase() + currentSite.slice(1).toLowerCase();
    switch (siteActionName) {
      case "Ccv":
        dispatch(setCcvCurrentPage(updatedPageNo));
        break;
      case "Rieker":
        dispatch(setRiekerCurrentPage(updatedPageNo));
        break;
      case "Mmax":
        dispatch(setMmaxCurrentPage(updatedPageNo));
        break;
      case "Mustang":
        dispatch(setMustangCurrentPage(updatedPageNo));
        break;
      case "Rdh":
        dispatch(setRdhCurrentPage(updatedPageNo));
        break;
      default:
        // Handle the case when currentSite doesn't match any known site
        break;
    }
    FetchOrders("page_load");
  };

  const handleNextClick = () => {
    dispatch(resetOrderSelectedRows());
    const updatedPageNo = parseInt(tempPageNo) + 1;
    setTempPageNo(updatedPageNo);
    if (tempPageNo > totalPages || !tempPageNo) {
      alert("Not a valid page number");
      return;
    }
    const siteActionName =
      currentSite.charAt(0).toUpperCase() + currentSite.slice(1).toLowerCase();
    switch (siteActionName) {
      case "Ccv":
        dispatch(setCcvCurrentPage(parseInt(updatedPageNo)));
        break;
      case "Rieker":
        dispatch(setRiekerCurrentPage(parseInt(updatedPageNo)));
        break;
      case "Mmax":
        dispatch(setMmaxCurrentPage(parseInt(updatedPageNo)));
        break;
      case "Mustang":
        dispatch(setMustangCurrentPage(parseInt(updatedPageNo)));
        break;
      case "Rdh":
        dispatch(setRdhCurrentPage(updatedPageNo));
        break;
      default:
        // Handle the case when currentSite doesn't match any known site
        break;
    }
  };

  const handleGotoPage = (e) => {
    dispatch(resetOrderSelectedRows());
    if (e.keyCode === 13) {
      if (tempPageNo > totalPages || !tempPageNo) {
        alert("Not a valid page number");
        setTempPageNo(1);
        return;
      }
      const siteActionName =
        currentSite.charAt(0).toUpperCase() +
        currentSite.slice(1).toLowerCase();
      switch (siteActionName) {
        case "Ccv":
          dispatch(setCcvCurrentPage(parseInt(tempPageNo)));
          break;
        case "Rieker":
          dispatch(setRiekerCurrentPage(parseInt(tempPageNo)));
          break;
        case "Mmax":
          dispatch(setMmaxCurrentPage(parseInt(tempPageNo)));
          break;
        case "Mustang":
          dispatch(setMustangCurrentPage(parseInt(tempPageNo)));
          break;
        case "Rdh":
          dispatch(setRdhCurrentPage(tempPageNo));
          break;
        default:
          // Handle the case when currentSite doesn't match any known site
          break;
      }
    }
  };

  const handleDirectPage = (e) => {
    dispatch(resetOrderSelectedRows());
    const goToPage = parseInt(e.target.innerText);
    goToPage === 1 ? setTempPageNo(1) : setTempPageNo(goToPage);
    const siteActionName =
      currentSite.charAt(0).toUpperCase() + currentSite.slice(1).toLowerCase();
    switch (siteActionName) {
      case "Ccv":
        dispatch(setCcvCurrentPage(parseInt(goToPage)));
        break;
      case "Rieker":
        dispatch(setRiekerCurrentPage(parseInt(goToPage)));
        break;
      case "Mmax":
        dispatch(setMmaxCurrentPage(parseInt(goToPage)));
        break;
      case "Mustang":
        dispatch(setMustangCurrentPage(parseInt(goToPage)));
        break;
      case "Rdh":
        dispatch(setRdhCurrentPage(parseInt(goToPage)));
        break;
      default:
        // Handle the case when currentSite doesn't match any known site
        break;
    }
  };

  const handlePageNo = (e) => {
    setTempPageNo(e.target.value);
  };

  return (
    <Container
      fluid
      className="pagination-section d-flex justify-content-center mb-5"
    >
      <Row className="pagination-section-row">
        <Col md={12}>
          <Row className="justify-content-center d-flex align-items-center">
            <Pagination>
              <Pagination.Item
                disabled={currentPage === 1}
                value={1}
                onClick={(e) => handleDirectPage(e)}
              >
                1
              </Pagination.Item>
              <Pagination.Prev
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              />
              <li className="page-item">
                <Form.Control
                  type="number"
                  placeholder={currentPage}
                  onChange={handlePageNo}
                  value={tempPageNo}
                  onKeyUp={(e) => handleGotoPage(e)}
                />
              </li>
              <Pagination.Next
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
              />
              <Pagination.Item
                disabled={currentPage === totalPages}
                value={totalPages}
                onClick={(e) => handleDirectPage(e)}
              >
                {totalPages}
              </Pagination.Item>
            </Pagination>
            <div className="mx-3">
              <label className="col-form-label">
                Affichage {pageStart}-{pageEnd} sur {checkCount} (page{" "}
                {currentPage} / {totalPages})
              </label>
            </div>
            <div className="mx-3">
              <PageSizeSelector
                pageSizeOptions={[35, 50, 100]}
                pageSize={pageSize}
                handlePageSize={handlePageSize}
                totalCount={checkCount}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default PaginationBottom;
